import React from "react";
import { closeiconblack, visaimg } from "../../resources/images";
import Button from "../button";
import styles from "./styles.module.css";
import CartProductCard from "../cartproductcard";

const CardtDataPopUp = (props) => {
  return (
    <div
      className={[props.customPoupStyle, styles.cartDataContainerStyle].join(
        " "
      )}
    >
      <div className={styles.cartDataCardsContainerStyle}>
        <div className={styles.cartHeaderContainerStyle}>
          <p className={styles.cartHeaderTextStyle}>Cart (2)</p>
          <div
            className={styles.closeIconWrapperStyle}
            onClick={props.onCloseClick}
          >
            <img src={closeiconblack} className={styles.imgStyle} />
          </div>
        </div>
        <CartProductCard />
        <CartProductCard />
      </div>
      <div className={styles.cartBtnContainerStyle}>
        <div className={styles.cardsContainerStyle}>
          <div className={styles.cardVisaImgWrapperStyle}>
            <img src={visaimg} className={styles.imgStyle} />
          </div>
          <div className={styles.cardVisaImgWrapperStyle}>
            <img src={visaimg} className={styles.imgStyle} />
          </div>
          <div className={styles.cardVisaImgWrapperStyle}>
            <img src={visaimg} className={styles.imgStyle} />
          </div>
          <div className={styles.cardVisaImgWrapperStyle}>
            <img src={visaimg} className={styles.imgStyle} />
          </div>
          <div className={styles.cardVisaImgWrapperStyle}>
            <img src={visaimg} className={styles.imgStyle} />
          </div>
          <div className={styles.cardVisaImgWrapperStyle}>
            <img src={visaimg} className={styles.imgStyle} />
          </div>
          <div className={styles.cardVisaImgWrapperStyle}>
            <img src={visaimg} className={styles.imgStyle} />
          </div>
          <div className={styles.cardVisaImgWrapperStyle}>
            <img src={visaimg} className={styles.imgStyle} />
          </div>
        </div>
        <Button
          btnName={"Go to Checkout"}
          btnCustomStyle={styles.btnCartStyle}
          onClick={props.addCartClick}
        />
      </div>
    </div>
  );
};

export default CardtDataPopUp;
