import React from "react";
import styles from "./styles.module.css";

const LabelInput = (props) => {
  return (
    <div className={styles.labelInputMainContainerStyle}>
      {props.labelName && (
        <label className={styles.labelTextStyle}>{props.labelName}</label>
      )}
      <div className={styles.inputInsideContainerStyle}>
        <input
          type={props.type}
          placeholder={props.placeholder}
          className={[props.labelInputStyle, styles.labelInputStyle].join(" ")}
        />
        {props.changeText && (
          <p className={styles.changeTextStyle}>{props.changeText}</p>
        )}
      </div>
    </div>
  );
};

export default LabelInput;
