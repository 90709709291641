import React from "react";
import styles from "./styles.module.css";
import { productimg } from "../../resources/images";

const CartProductCard = (props) => {
  return (
    <div className={styles.productCardContainerStyle} onClick={props.onClick}>
      <div className={styles.productImgWrapperStyle}>
        <img src={productimg} className={styles.imgStyle} />
      </div>
      <div className={styles.productCardTextContainerStyle}>
        <p className={styles.productNameTextStyle}>
          337 shoe, this is a ver very very long title
        </p>
        <p className={styles.productPriceTextStyle}>New Balance</p>
        <p className={styles.productInteractionTextStyle}>Remove</p>
      </div>
    </div>
  );
};

export default CartProductCard;
