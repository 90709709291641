import React from "react";
import styles from "./styles.module.css";

const Button = (props) => {
  return (
    <button
      className={[props.btnCustomStyle, styles.btnStyle].join(" ")}
      onClick={props.onClick}
    >
      {props.leftImage && (
        <div
          className={[props.imgLeftCustomStyle, styles.imageWrapperStyle].join(
            " "
          )}
        >
          <img src={props.leftImage} className={styles.imgStyle} />
        </div>
      )}
      {props.btnName}
      {props.rightImage && (
        <div
          className={[props.imgRightCustomStyle, styles.imageWrapperStyle].join(
            " "
          )}
        >
          <img src={props.rightImage} className={styles.imgStyle} />
        </div>
      )}
    </button>
  );
};

export default Button;
