import React, { useState } from "react";
import PrimarySearchAppBar from "../../components/navbar";
import {
  interactionarrowicon,
  moneyicon,
  streamimg,
  leftarrowwhitestatusbaricon,
  videostatusbarprofileimg,
  eyewatchingicon,
  shareicon,
  minimiseicon,
  hearticon,
  smileyicon,
  sendbtnicon,
  basketblackicon,
  listblackicon,
  shareblackicon,
  productimg,
  clickwhiteicon,
} from "../../resources/images";
import ProductCard from "../../components/productcard";
import Button from "../../components/button";
import ChatCard from "../../components/chatcard";
import ProductAdvCard from "../../components/productadvcard";
import TextInput from "../../components/input";
import AddToCartPopup from "../../components/addtocartpopup";
import CardtDataPopUp from "../../components/cartdatapopup";
import useWindowDimensions from "../../hooks/usewindowdimensions";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";

const ProductPage = () => {
  const navigation = useNavigate();
  const { width } = useWindowDimensions();
  const [addtoCart, setAddtoCart] = useState(false);
  const [cartData, setCartData] = useState(false);
  const [cartDataView, setCartDataView] = useState(false);
  const renderProductsView = () => {
    return (
      <div className={styles.productLeftContainerStyle}>
        <div className={styles.priceContainerStyle}>
          <div className={styles.interactionsContainerStyle}>
            <div className={styles.iconWrapperStyle}>
              <img src={interactionarrowicon} className={styles.imgStyle} />
            </div>
            <p className={styles.textStyle}>INTERACTIONS : 34</p>
          </div>
          <div className={styles.interactionsContainerStyle}>
            <div className={styles.iconWrapperStyle}>
              <img src={moneyicon} className={styles.imgStyle} />
            </div>
            <p className={styles.textStyle}>EARNINGS : $3400</p>
          </div>
        </div>
        <div className={styles.productsDisplayContainerStyle}>
          <div className={styles.productHeaderContainerStyle}>
            <p className={styles.text2Style}>Products (3)</p>
          </div>
          <ProductCard onClick={() => setAddtoCart(!addtoCart)} />
          <ProductCard onClick={() => setAddtoCart(!addtoCart)} />
          <ProductCard onClick={() => setAddtoCart(!addtoCart)} />
        </div>
      </div>
    );
  };
  const renderVideoView = () => {
    return (
      <div className={styles.videoMainContainerStyle}>
        <div className={styles.videoInsideContainerStyle}>
          <div className={styles.videoContainerStyle}>
            <div className={styles.streamImgContainerStyle}>
              <img src={streamimg} className={styles.imgVideoStyle} />
            </div>
            <div className={styles.videoStatusBarContainerStyle}>
              <div className={styles.videoStatusbarContainerStyle}>
                <div className={styles.backButtonContainerStyle}>
                  <div className={styles.arrowIconWrapperStyle}>
                    <img
                      src={leftarrowwhitestatusbaricon}
                      className={styles.imgStyle}
                    />
                  </div>
                  <div className={styles.videoProfileImgWrapperStyle}>
                    <img
                      src={videostatusbarprofileimg}
                      className={styles.imgStyle}
                    />
                  </div>
                </div>
                <div className={styles.videoStatusBarProfileNameContainerStyle}>
                  <p className={styles.collabTextStyle}>In colabs with Nike</p>
                  <p className={styles.nameTextStyle}>Diana Dodge</p>
                </div>
              </div>
              <div className={styles.buttonsContainerStyle}>
                <Button
                  btnName={"Follow"}
                  btnCustomStyle={styles.followBtnStyle}
                />
                <Button btnName={"LIVE"} btnCustomStyle={styles.liveBtnStyle} />
                <Button
                  btnName={"118"}
                  leftImage={eyewatchingicon}
                  btnCustomStyle={styles.eyeBtnStyle}
                />
              </div>
            </div>
            <div className={styles.minimizeBtnContainerStyle}>
              <img src={minimiseicon} className={styles.imgStyle} />
            </div>
            <div className={styles.heartIconBtnContainerStyle}>
              <img src={hearticon} className={styles.imgStyle} />
            </div>
            <div
              className={styles.mobileProductViewStyle}
              onClick={() => setAddtoCart(!addtoCart)}
            >
              <div className={styles.mobileProductImgWrapperStyle}>
                <img src={productimg} className={styles.imgStyle} />
              </div>
              <Button
                btnName={"118"}
                leftImage={clickwhiteicon}
                btnCustomStyle={styles.mobileProductBtnStyle}
              />
            </div>
          </div>
          <div className={styles.videoStremDetailsContainerStyle}>
            <p className={styles.streamDetailsTextStyle}>
              Rea Spelzini What I Picked And In Trend Right Now And In Trend
              Right Now
            </p>
            <div className={styles.dateShareContainerStyle}>
              <p className={styles.dateTextStyle}>23 September 2022</p>
              <div className={styles.shareContainerStyle}>
                <div className={styles.arrowIconWrapperStyle}>
                  <img src={shareicon} className={styles.imgStyle} />
                </div>
                <p className={styles.shareTextStyle}>Share</p>
              </div>
            </div>
          </div>
        </div>
        {addtoCart && width > 740 && width < 1080 && (
          <div className={styles.cartDataPopOverCOntainerStyle}>
            <AddToCartPopup
              popupCustomStyle={styles.customPopupAbove740Style}
              onClickClose={() => setAddtoCart(!addtoCart)}
              onBtnClick={() => {
                setAddtoCart(!addtoCart);
                setCartDataView(!cartDataView);
              }}
            />
          </div>
        )}
      </div>
    );
  };
  const renderChatView = () => {
    return (
      <div className={styles.chatViewContainerStyle}>
        <div className={styles.chatViewInsideContainerStyle}>
          <div className={styles.chatHeaderStyle}></div>
          <ChatCard steamer={true} />
          <ChatCard />
          <ChatCard />
          <ProductAdvCard highlitened={true} />
          <ChatCard steamer={true} />
          <ChatCard />
          <ChatCard />
          <ChatCard steamer={true} />
          <ChatCard />
          <ChatCard />

          {width > 740 ? (
            <div className={styles.chatInputContainerStyle}>
              <TextInput placeholder={"Message..."} image={smileyicon} />
              <div className={styles.dateShareContainerStyle}>
                <p className={styles.countTextStyle}>
                  6<span className={styles.totalCountTextStyle}>/300</span>
                </p>
                <div className={styles.shareContainerStyle}>
                  <div className={styles.arrowIconWrapperStyle}>
                    <img src={sendbtnicon} className={styles.imgStyle} />
                  </div>
                  <p className={styles.sendTextStyle}>SEND</p>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.mobileChatInputContainerStyle}>
              <TextInput placeholder={"Add Comment..."} />
              <div className={styles.chatBoxIconWrapperStyle}>
                <img src={shareblackicon} className={styles.imgStyle} />
              </div>
              <div className={styles.chatBoxIconWrapperStyle}>
                <img src={listblackicon} className={styles.imgStyle} />
              </div>
              <div
                className={styles.chatBoxIconWrapperStyle}
                onClick={() => setCartData(!cartData)}
              >
                <img src={basketblackicon} className={styles.imgStyle} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.containerStyle}>
      {cartDataView && <div className={styles.transparentStyle}></div>}
      {cartDataView && (
        <div className={styles.cartDataViewStyle}>
          <CardtDataPopUp
            customPoupStyle={styles.cartViewStyle}
            onCloseClick={() => setCartDataView(!cartDataView)}
            addCartClick={() => navigation("/checkout")}
          />
        </div>
      )}
      <PrimarySearchAppBar onClickBag={() => setCartDataView(!cartDataView)} />
      <div className={styles.productMainContainerStyle}>
        <div className={styles.cartPoOverContainerStyle}>
          {addtoCart ? (
            <>{renderProductsView()} </>
          ) : (
            <AddToCartPopup
              onClickClose={() => setAddtoCart(!addtoCart)}
              onBtnClick={() => {
                setAddtoCart(!addtoCart);
                setCartData(!cartData);
              }}
            />
          )}
          {cartData && (
            <div className={styles.cartDataPopOverOutSIdeCOntainerStyle}>
              <CardtDataPopUp
                onCloseClick={() => setCartData(!cartData)}
                addCartClick={() => navigation("/checkout")}
              />
            </div>
          )}
        </div>

        {renderVideoView()}

        {width > 740 ? (
          <>{renderChatView()}</>
        ) : (
          <>
            {renderChatView()}
            {addtoCart && (
              <div className={styles.cartDataPopOverOutSIdeCOntainerStyle}>
                <AddToCartPopup
                  popupCustomStyle={styles.customPopupStyle}
                  onClickClose={() => setAddtoCart(!addtoCart)}
                  onBtnClick={() => {
                    setAddtoCart(!addtoCart);
                    setCartData(!cartData);
                  }}
                />
              </div>
            )}
            {cartData && (
              <div className={styles.cartDataPopOverOutSIdeCOntainerStyle}>
                <CardtDataPopUp
                  onCloseClick={() => setCartData(!cartData)}
                  addCartClick={() => navigation("/checkout")}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProductPage;
