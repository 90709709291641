import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import ProductPage from "../pages/productpage";
import CheckOutPage from "../pages/checkoutpage";
const AppRoutes = () => {
  return useRoutes([
    {
      path: "/",
      element: <ProductPage />,
    },
    {
      path: "/checkout",
      element: <CheckOutPage />,
    },
  ]);
};
export default AppRoutes;
