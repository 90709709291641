import React from "react";
import styles from "./styles.module.css";

const TextInput = (props) => {
  return (
    <div
      className={[
        props.inputContainerStyle,
        styles.inputMainContainerStyle,
      ].join(" ")}
    >
      <input
        type={props.type}
        placeholder={props.placeholder}
        onChange={props.onChange}
        className={[props.inputStyle, styles.inputStyles].join(" ")}
      />
      {props.image && (
        <div className={styles.imgWrapperStyle}>
          <img src={props.image} className={styles.imgStyle} />
        </div>
      )}
      {props.nameText && (
        <p className={styles.nameTextStyle}>{props.nameText}</p>
      )}
    </div>
  );
};

export default TextInput;
