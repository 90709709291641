import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import styles from "./styles.module.css";
import { onbazlogo, bagicon, profileimg } from "../../resources/images";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",

  "& .MuiInputBase-input": {
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    padding: "0 0 0 24px",
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "black",
    [theme.breakpoints.up("md")]: {
      width: "313px",
      height: "36px",
    },
    [theme.breakpoints.down("md")]: {
      width: "260px",
      height: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      width: "100%",
      height: "28px",
      padding: "0 0 0 4px",
      paddingRight: `calc(1em + ${theme.spacing(1)})`,
    },
  },
}));

export default function PrimarySearchAppBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={props.onClickBag}>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <div className={styles.bagIconWrapperStyle}>
              <img src={bagicon} className={styles.imgStyle} />
            </div>
          </Badge>
        </IconButton>
        <p>Shoping Cart</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <div className={styles.profileMobileImgWrapperStyle}>
            <img src={profileimg} className={styles.imgStyle} />
          </div>
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          boxShadow: "none",
          padding: "0 60px",
          backgroundColor: "white",
          "@media (max-width: 900px)": {
            padding: "0 24px",
          },
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            "@media (min-width: 600px)": {
              minHeight: "80px",
              padding: "0",
            },
            "@media (max-width: 600px)": {
              padding: "0",
              gap: "24px",
            },
            "@media (max-width: 400px)": {
              minHeight: "120px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "40px",
              "@media (max-width: 600px)": {
                gap: "24px",
              },
              "@media (max-width: 400px)": {
                flexDirection: "column",
                gap: "8px",
              },
            }}
          >
            <div className={styles.logoContainerStyle}>
              <img src={onbazlogo} className={styles.imgStyle} />
            </div>
            <Search
              sx={{
                boxShadow:
                  " 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 0px 2px 0px rgba(0, 0, 0, 0.12);",
                margin: "0",
                "@media (min-width: 600px)": {
                  margin: "0",
                },
              }}
            >
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
              <SearchIconWrapper
                sx={{
                  top: 0,
                  right: 0,
                  padding: "0 24px 0 0",
                  "@media (max-width: 600px)": {
                    padding: "0 8px 0 0",
                  },
                  "@media (max-width: 400px)": {
                    padding: "0 0 0 0",
                  },
                }}
              >
                <SearchIcon style={{ color: "black" }} />
              </SearchIconWrapper>
            </Search>
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
              onClick={props.onClickBag}
            >
              <Badge badgeContent={0} color="error">
                <div className={styles.bagIconWrapperStyle}>
                  <img src={bagicon} className={styles.imgStyle} />
                </div>
              </Badge>
            </IconButton>

            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <div className={styles.profileImgWrapperStyle}>
                <img src={profileimg} className={styles.imgStyle} />
              </div>
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              sx={{
                "@media (max-width: 600px)": {
                  padding: "0",
                },
              }}
            >
              <MoreIcon sx={{ color: "black" }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
