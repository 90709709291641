import React from "react";
import PrimarySearchAppBar from "../../components/navbar";
import TextInput from "../../components/input";
import Button from "../../components/button";
import DropDownInput from "../../components/dropdowninput";
import {
  klarnaimg,
  moreiconblack,
  productimg,
  tickmarkicon,
  visaimg,
  mcafreeimg,
  nortanimg,
} from "../../resources/images";
import LabelInput from "../../components/labelinput";
import styles from "./styles.module.css";

const CheckOutPage = () => {
  const country = [
    {
      value: "india",
      name: "india",
    },
    {
      value: "austrailia",
      name: "austrailia",
    },
    {
      value: "india",
      name: "india",
    },
    {
      value: "austrailia",
      name: "austrailia",
    },
  ];
  const renderInformation = () => {
    return (
      <div className={styles.informationContainerStyle}>
        <div className={styles.infoInsideContainerStyle}>
          <p className={styles.navTextStyle}>Information</p>
          <div className={styles.detailsOutsideContainerStyle}>
            <div className={styles.contactContainerStyle}>
              <p className={styles.textHeaderStyle}>Contact</p>
              <TextInput placeholder={"Email"} inputStyle={styles.inputStyle} />
            </div>
            <div className={styles.inputOutsideContainerStyle}>
              <p className={styles.detailsHeaderTextStyle}>Delivery Address</p>
              <div className={styles.inputsContainerStyle}>
                <DropDownInput
                  labelName={"Country"}
                  data={country}
                  onChange={(e) => console.log(e.target.value)}
                />
                <TextInput
                  placeholder={"First Name"}
                  inputStyle={styles.inputStyle}
                />
                <TextInput
                  placeholder={"Last Name"}
                  inputStyle={styles.inputStyle}
                />
                <TextInput
                  placeholder={"Address"}
                  inputStyle={styles.inputStyle}
                />
                <TextInput
                  placeholder={"Post Code"}
                  inputStyle={styles.inputStyle}
                />
                <TextInput
                  placeholder={"City"}
                  inputStyle={styles.inputStyle}
                />
                <TextInput
                  placeholder={"Mobile Number"}
                  inputStyle={styles.inputStyle}
                />
              </div>
            </div>
          </div>
          <Button
            btnName={"Add Shipping Method"}
            btnCustomStyle={styles.btnStyle}
          />
        </div>
      </div>
    );
  };
  const renderShippingInfo = () => {
    return (
      <div className={styles.informationContainerStyle}>
        <div className={styles.infoInsideContainerStyle}>
          <p className={styles.navTextStyle}>Information Shipping</p>
          <div className={styles.shippingDetailsOutsideContainerStyle}>
            <p className={styles.textHeaderStyle}>Shipping</p>
            <div className={styles.shippingOutsideContainerStyle}>
              <LabelInput
                labelName={"Contact"}
                type={"text"}
                placeholder={"name.."}
                changeText={"Change"}
              />
              <LabelInput
                labelName={"Send to"}
                type={"text"}
                placeholder={"name.."}
                changeText={"Change"}
              />
            </div>
          </div>
          <div className={styles.inputOutsideContainerStyle}>
            <p className={styles.detailsHeaderTextStyle}>Shipping Method</p>
            <div className={styles.inputsContainerStyle}>
              <TextInput
                placeholder={"...."}
                inputStyle={styles.inputStyle}
                nameText={"FREE"}
              />
              <TextInput
                placeholder={"....."}
                inputStyle={styles.inputStyle}
                nameText={"FREE"}
              />
            </div>
          </div>
          <Button
            btnName={"Add Payment Details"}
            btnCustomStyle={styles.btnStyle}
          />
        </div>
      </div>
    );
  };
  const renderProductsInfo = () => {
    return (
      <div className={styles.productsContainerStyle}>
        <div className={styles.productsCheckoutContainerStyle}>
          <div className={styles.productsCoupanContainerSTyle}>
            <div className={styles.productsCountContainerStyle}>
              <div
                className={styles.productCardDataContainerStyle}
                //   onClick={props.onClick}
              >
                <div className={styles.productImgWrapperStyle}>
                  <img src={productimg} className={styles.imgStyle} />
                </div>
                <div className={styles.productCardTextContainerStyle}>
                  <p className={styles.productNameTextStyle}>
                    337 shoe, this is a ver very very long title
                  </p>
                  <p className={styles.productBalanceTextStyle}>New Balance</p>
                  <p className={styles.colorTextStyle}>White</p>
                  <p className={styles.quantityTextStyle}>1</p>
                </div>
                <p className={styles.productPriceTextStyle}>800 kr</p>
              </div>
              <div
                className={styles.productCardDataContainerStyle}
                //   onClick={props.onClick}
              >
                <div className={styles.productImgWrapperStyle}>
                  <img src={productimg} className={styles.imgStyle} />
                </div>
                <div className={styles.productCardTextContainerStyle}>
                  <p className={styles.productNameTextStyle}>
                    337 shoe, this is a ver very very long title
                  </p>
                  <p className={styles.productBalanceTextStyle}>New Balance</p>
                  <p className={styles.colorTextStyle}>White</p>
                  <p className={styles.quantityTextStyle}>1</p>
                </div>
                <p className={styles.productPriceTextStyle}>800 kr</p>
              </div>
            </div>
            <div className={styles.couponContainerStyle}>
              <TextInput
                placeholder={"Discount Code"}
                inputStyle={styles.inputCoupanStyle}
              />
              <p className={styles.applyBtnStyle}>Apply Code</p>
            </div>
          </div>
          <div className={styles.bottomContainerStyle}>
            <div className={styles.totalPurchaseContainerStyle}>
              <div className={styles.totalTextContainerSTyle}>
                <p className={styles.textTotalStyle}>Subtotal</p>
                <p className={styles.textTotalStyle}>2449 kr</p>
              </div>
              <div className={styles.totalTextContainerSTyle}>
                <p className={styles.textTotalStyle}>Shipping</p>
                <p className={styles.textTotalStyle}>59 kr</p>
              </div>
              <div className={styles.totalTextContainerSTyle}>
                <p className={styles.textTotalStyle}>Total</p>
                <p className={styles.textTotalStyle}>SEK 2808</p>
              </div>
            </div>
            <p className={styles.taxTextStyle}>Incl. SEK 321.60 VAT</p>
          </div>
        </div>
      </div>
    );
  };
  const renderPaymentDetails = () => {
    return (
      <div className={styles.informationContainerStyle}>
        <div className={styles.infoInsideContainerStyle}>
          <p className={styles.navTextStyle}>Information Shipping payment</p>
          <div className={styles.shippingOutsideContainerStyle}>
            <LabelInput
              labelName={"Contact"}
              type={"text"}
              placeholder={"name.."}
              changeText={"Change"}
            />
            <LabelInput
              labelName={"Send to"}
              type={"text"}
              placeholder={"name.."}
              changeText={"Change"}
            />
            <LabelInput
              labelName={"Send to"}
              type={"text"}
              placeholder={"name.."}
              changeText={"Change"}
            />
          </div>
          <div className={styles.shippingDetailsOutsideContainerStyle}>
            <p className={styles.textHeaderStyle}>Payment Method</p>
            <div className={styles.paymentTextContainerSTyle}>
              <p className={styles.paymentMethodTextStyle}>
                Choose the address that matches your card or payment method
              </p>
              <div className={styles.paymentDetailsContainerStyle}>
                <div className={styles.radioBtnContainerStyle}>
                  <div className={styles.radioInputStyle}>
                    <div className={styles.radioInputBtnStyle}></div>
                  </div>
                  <p className={styles.detailsHeaderTextStyle}>Cards</p>
                </div>
                <div className={styles.cardsContainerStyle}>
                  <div className={styles.cardVisaImgWrapperStyle}>
                    <img src={visaimg} className={styles.imgStyle} />
                  </div>
                  <div className={styles.cardVisaImgWrapperStyle}>
                    <img src={visaimg} className={styles.imgStyle} />
                  </div>
                  <div className={styles.cardVisaImgWrapperStyle}>
                    <img src={visaimg} className={styles.imgStyle} />
                  </div>
                  <div className={styles.cardVisaImgWrapperStyle}>
                    <img src={visaimg} className={styles.imgStyle} />
                  </div>
                  <div className={styles.cardVisaImgWrapperStyle}>
                    <img src={visaimg} className={styles.imgStyle} />
                  </div>
                  <div className={styles.cardVisaImgWrapperStyle}>
                    <img src={visaimg} className={styles.imgStyle} />
                  </div>
                  <div className={styles.cardVisaImgWrapperStyle}>
                    <img src={visaimg} className={styles.imgStyle} />
                  </div>
                  <div className={styles.cardVisaImgWrapperStyle}>
                    <img src={visaimg} className={styles.imgStyle} />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.shippingOutsideContainerStyle}>
              <TextInput
                placeholder={"Card Number"}
                inputStyle={styles.inputStyle}
              />
              <TextInput
                placeholder={"Name on the card"}
                inputStyle={styles.inputStyle}
              />
              <TextInput
                placeholder={"Expiration Date"}
                inputStyle={styles.inputStyle}
              />
              <TextInput placeholder={"CVV"} inputStyle={styles.inputStyle} />
            </div>
            <div>
              <div className={styles.radioOptionInputContainerStyle}>
                <div className={styles.radioBtnContainerStyle}>
                  <div className={styles.radioInputStyle}>
                    <div className={styles.radioInputBtnStyle}></div>
                  </div>
                  <p className={styles.detailsHeaderTextStyle}>
                    Pay later with Klarna
                  </p>
                </div>
                <div className={styles.klarnaWrapperStyle}>
                  <img src={klarnaimg} className={styles.imgStyle} />
                </div>
              </div>
              <div className={styles.radioOptionInputContainerStyle}>
                <div className={styles.radioBtnContainerStyle}>
                  <div className={styles.radioInputStyle}>
                    <div className={styles.radioInputBtnStyle}></div>
                  </div>
                  <p className={styles.detailsHeaderTextStyle}>
                    Pay now with Klarna
                  </p>
                </div>
                <div className={styles.klarnaWrapperStyle}>
                  <img src={klarnaimg} className={styles.imgStyle} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputOutsideContainerStyle}>
            <p className={styles.detailsHeaderTextStyle}>Billing Address</p>
            <div className={styles.bottomBtnContainerStyle}>
              <div className={styles.sameAsBtnContainerStyle}>
                <div className={styles.tickMarkImgWrapperStyle}>
                  <img src={tickmarkicon} className={styles.imgStyle} />
                </div>
                <p className={styles.sameAsTextStyle}>
                  Same as shipping address
                </p>
              </div>
              <Button
                btnName={"Review Order"}
                btnCustomStyle={styles.btnStyle}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderNavigation = () => {
    return (
      <div>
        <p className={styles.navigationTextStyle}>
          Information Shipping Payment Overview
        </p>
      </div>
    );
  };
  const renderOrderedProducts = () => {
    return (
      <div className={styles.deliveryProductCOntainerStyle}>
        <p className={styles.deliveryTextStyle}>Delivery</p>
        <div
          className={styles.productCardContainerStyle}
          //   onClick={props.onClick}
        >
          <div className={styles.productRightContainerStyle}>
            <div className={styles.productImgWrapperStyle}>
              <img src={productimg} className={styles.imgStyle} />
            </div>
            <div className={styles.productCardTextContainerStyle}>
              <p className={styles.productNameTextStyle}>
                337 shoe, this is a ver very very long title
              </p>
              <p className={styles.productBalanceTextStyle}>New Balance</p>
              <p className={styles.colorTextStyle}>White</p>
              <p className={styles.quantityTextStyle}>1</p>
            </div>
          </div>
          <p className={styles.productPriceTextStyle}>800 kr</p>
        </div>
      </div>
    );
  };
  const renderOrderSummery = () => {
    return (
      <div className={styles.summeryContainerStyle}>
        <div className={styles.summeryTotalPriceCOntainerStyle}>
          <div className={styles.summeryInsideContainerSTyle}>
            <p className={styles.deliveryTextStyle}>Order Summary</p>
            <div>
              <div className={styles.totalTextContainerStyle}>
                <div className={styles.priceContainerStyle}>
                  <p className={styles.priceTextStyle}>Subtotal</p>
                  <p className={styles.priceStyle}>$50</p>
                </div>
                <div className={styles.priceContainerStyle}>
                  <p className={styles.priceTextStyle}>Subtotal</p>
                  <p className={styles.priceStyle}>$50</p>
                </div>
                <div className={styles.priceContainerStyle}>
                  <p className={styles.priceTextStyle}>Subtotal</p>
                  <p className={styles.priceStyle}>$50</p>
                </div>
              </div>
              <div className={styles.borderStyle}></div>
              <div className={styles.priceContainerStyle}>
                <p className={styles.priceTextStyle}>Subtotal</p>
                <p className={styles.priceStyle}>$50</p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div className={styles.securityContainerStyle}>
          <div className={styles.imageWrapperStyle}>
            <img src={mcafreeimg} className={styles.imgStyle} />
          </div>
          <div className={styles.imageWrapperStyle}>
            <img src={nortanimg} className={styles.imgStyle} />
          </div>
        </div>
        <p className={styles.privacyTextStyle}>
          By placing this order, you agree to our{" "}
          <span className={styles.linkTextStyle}>Privacy Policy</span>
          and <span className={styles.linkTextStyle}>Term & Conditions</span>
        </p>
        <Button btnName={"Place Order"} btnCustomStyle={styles.buttonStyle} />
      </div>
    );
  };
  return (
    <div className={styles.containerStyle}>
      <PrimarySearchAppBar />
      <div className={styles.insideContainerStyle}>
        {/* {renderInformation()} */}
        {/* {renderShippingInfo()} */}
        {renderPaymentDetails()}
        {renderProductsInfo()}
      </div>
      {/* <div className={styles.mainContainerStyle}>
        {renderNavigation()}
        <div className={styles.insideSummeryContainerStyle}>
          {renderOrderedProducts()}
          {renderOrderSummery()}
        </div>
      </div> */}
    </div>
  );
};

export default CheckOutPage;
