import React from "react";
import styles from "./styles.module.css";
import { productimg, moreiconblack } from "../../resources/images";
import Button from "../button";

const ProductAdvCard = (props) => {
  return (
    <div className={styles.productCardContainerStyle}>
      <div className={styles.productImgWrapperStyle}>
        <img src={productimg} className={styles.imgStyle} />
      </div>
      <div className={styles.productAdvCardContainerStyle}>
        <div className={styles.productCardTextContainerStyle}>
          <p className={styles.productNameTextStyle}>XC-71</p>
          {props.highlitened && (
            <Button
              btnName={"Streamer"}
              btnCustomStyle={styles.highlightenedBtnStyle}
            />
          )}
        </div>
        <div className={styles.productAdvDetailsContainerStyle}>
          <p className={styles.productPriceTextStyle}>New Balance</p>
          <p className={styles.productInteractionTextStyle}>
            45 people interacted with it
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductAdvCard;
