import React from "react";
import styles from "./styles.module.css";
import { closeiconblack, productimg } from "../../resources/images";
import DropDownInput from "../dropdowninput";
import Button from "../button";

const AddToCartPopup = (props) => {
  const quantity = [
    {
      value: 1,
      name: "1",
    },
    {
      value: 2,
      name: "2",
    },
    {
      value: 3,
      name: "3",
    },
  ];
  return (
    <div
      className={[props.popupCustomStyle, styles.popupContainerStyle].join(" ")}
    >
      <div className={styles.popUpImgContainerStyle}>
        <div className={styles.productImgContainerStyle}>
          <div className={styles.watchWrapperStyle}>
            <p className={styles.watchTextStyle}>Watch Video</p>
          </div>
          <div className={styles.productImgWrapperStyle}>
            <img src={productimg} className={styles.imgStyle} />
          </div>
          <div className={styles.productTextContainerStyle}>
            <p className={styles.productNameStyle}>D6MICKEY TOP</p>
            <p className={styles.priceNameStyle}>$150</p>
          </div>
        </div>
        <div
          className={styles.closeIconWrapperStyle}
          onClick={props.onClickClose}
        >
          <img src={closeiconblack} className={styles.imgStyle} />
        </div>
      </div>
      <div className={styles.poupContentContainerStyle}>
        <div className={styles.productDetailsContainerStyle}>
          <p className={styles.detailsTextStyle}>
            The Care+Wear X N Natori Collection.Designed with the input from
            nearly 100 clinicians,medical professionals, and commercial
            launderersto create a premium line of scrubs & healthwear.
          </p>
          <p className={styles.readlessTextStyle}>read less</p>
        </div>
        <div className={styles.inputsContainerStyle}>
          <div className={styles.dropDownContainerStyle}>
            <DropDownInput
              labelName={"Quantity"}
              data={quantity}
              onChange={(e) => console.log(e.target.value)}
            />
            <DropDownInput
              labelName={"Size"}
              data={quantity}
              onChange={(e) => console.log(e.target.value)}
            />
            <DropDownInput
              labelName={"Color"}
              data={quantity}
              onChange={(e) => console.log(e.target.value)}
            />
          </div>
          <Button
            btnName={"Add to cart"}
            btnCustomStyle={styles.btnStyles}
            onClick={props.onBtnClick}
          />
        </div>
      </div>
    </div>
  );
};

export default AddToCartPopup;
