import React, { createRef } from "react";
import styles from "./styles.module.css";
import { downarrowblackicon } from "../../resources/images";

const DropDownInput = (props) => {
  return (
    <div className={styles.mainContainerStyle}>
      <div className={styles.dropDownContainerStyle}>
        <label className={styles.labelTextStyle}>{props.labelName}</label>
        <select
          className={styles.selectContainerStyle}
          onChange={props.onChange}
        >
          {props.data?.map((item, index) => (
            <option
              key={index}
              value={item.value}
              className={styles.optionContainerStyle}
            >
              {item.name}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.imgWrapperStyle}>
        <img src={downarrowblackicon} className={styles.imgStyle} />
      </div>
    </div>
  );
};

export default DropDownInput;
