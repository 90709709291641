import React from "react";
import {
  moreiconblack,
  videostatusbarprofileimg,
} from "../../resources/images";
import styles from "./styles.module.css";
import Button from "../button";

const ChatCard = (props) => {
  return (
    <div className={styles.chatCardContainerStyle}>
      <div className={styles.chatProfileImgWrapperStyle}>
        <img src={videostatusbarprofileimg} className={styles.imgStyle} />
      </div>
      <div className={styles.chatDetailsContainerStyle}>
        <div className={styles.itemContainerStyle}>
          <p className={styles.personNameTextStyle}>Rudoph</p>
          {props.steamer && (
            <Button
              btnName={"Streamer"}
              btnCustomStyle={styles.streamerBtnStyle}
            />
          )}
        </div>
        <div className={styles.itemContainerStyle}>
          <p className={styles.chatTextStyle}>
            Hi everyone, welcome to my Midi Dress Styling! We'll get started in
            a few minutes 💖
          </p>
          <div className={styles.moreIconWrapperStyle}>
            <img src={moreiconblack} className={styles.imgStyle} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatCard;
