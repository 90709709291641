import React from "react";
import styles from "./styles.module.css";
import { productimg, moreiconblack } from "../../resources/images";

const ProductCard = (props) => {
  return (
    <div className={styles.productCardContainerStyle} onClick={props.onClick}>
      <div className={styles.productImgWrapperStyle}>
        <img src={productimg} className={styles.imgStyle} />
      </div>
      <div className={styles.productCardTextContainerStyle}>
        <p className={styles.productNameTextStyle}>
          337 shoe, this is a ver very very long title
        </p>
        <p className={styles.productPriceTextStyle}>New Balance</p>
        <p className={styles.productInteractionTextStyle}>
          45 people interacted with it
        </p>
      </div>
      <div className={styles.optionsImgWrapperStyle}>
        <img src={moreiconblack} className={styles.imgStyle} />
      </div>
    </div>
  );
};

export default ProductCard;
